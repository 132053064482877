import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, mergeMap, retry, retryWhen, tap, throwError, timer } from 'rxjs';
import { HandleErrorService } from "./handle-error.service";
import { environment } from 'src/environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { NavigationService } from './service/navigation.service';
import { okta2FaAcrConfig } from './constants/auth.constant';

@Injectable()
export class ErrorInterceptInterceptor implements HttpInterceptor {

  constructor(
    private error: HandleErrorService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public navigationService: NavigationService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
            retryWhen(errors => 
              errors.pipe(
                mergeMap((error: any, index: any) => {
                  if(error.status == 401){
                    this.oktaAuth.signInWithRedirect({acrValues: okta2FaAcrConfig});
                    return throwError(error);
                  }
                  if(error.status == 403){
                    this.navigationService.navigateTo(['access-denied']);
                    return throwError(error);
                  }
                  if((error.status >= 400) && error.status < 600 && index < 1) {
                    return timer(1000);
                  }
                  return throwError(error);
                })
              )
            ),
            catchError((error: HttpErrorResponse) => {
                let error_msg: any;
                error_msg = this.error.handleError(error);
                return throwError(() => {
                  return error_msg;
                });
            })
        )
  }
}
