<div [ngClass]="customClass" class="alcon-ts-container cursor-pointer" (click)="toggleSwitch()" [style.transform]="'scale(' + scaleValue + ')'">
    <div class="alcon-ts-label" *ngIf="label">
        {{label}}
    </div>
    <div class="alcon-ts-shell" [ngClass]="{'alcon-ts-shell--selected': selected}">
        <div class="alcon-ts-button-state" *ngIf="!hideButtonStateLabel">
            <div class="shell-on-text"><span>{{onText}}</span></div>
            <div class="shell-off-text"><span>{{offText}}</span></div>
        </div>
        <div class="alcon-ts-knob"></div>
    </div>
</div>