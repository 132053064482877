import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { adiStorageKey } from './constants/auth.constant';
import { NavigationService } from './service/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(private navigationService: NavigationService) {}

  public redirectIfNeeded(): void {
    const oldURL = environment.oldCCURL;
    if (window.location.href.includes(oldURL) && !window.location.href.includes('redirect')) {
      this.navigationService.navigateTo(['redirect']);
    }
  }
}