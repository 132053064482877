import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderModule } from 'src/app/components/page-header/page-header.module';
import { TabNavigationModule } from 'src/app/components/tab-navigation/tab-navigation.module';
import * as MenuItems from 'src/assets/menus.json';
import { IolPreferencesFilterComponent } from '../../components/iol-preferences-filter/iol-preferences-filter.component';
import { IolModelsComponent } from '../../components/iol-models/iol-models.component';
import { IolModelPreferencesService } from '../../services/iol-model-preferences.service';
import { hiddenPayload } from '../../iol-model-preferences.model';
import { SectionLoaderModule } from 'src/app/components/section-loader/section-loader.module';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { AlconToastMessageService } from 'src/app/shared/alcon-toast-message.service';
import { customDialog } from 'src/app/components/modal-dialogs/dialog.model';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogComponent, dialogAction } from 'src/app/components/modal-dialogs/custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-iol-model-preferences',
  standalone: true,
  imports: [TabNavigationModule, PageHeaderModule, IolModelsComponent, IolPreferencesFilterComponent, SectionLoaderModule, CommonModule],
  templateUrl: './iol-model-preferences.component.html',
  styleUrl: './iol-model-preferences.component.scss'
})
export class IolModelPreferencesComponent implements OnInit, OnDestroy{
 
  menus: any = (MenuItems as any).default;
  isLoader = false;
  noVisibleSearchResult = false;
  noHiddenSearchResult = false;
  showNoResult = false;
  private loaderSubscription!: Subscription;

  constructor(
    private iolModelPreferencesService: IolModelPreferencesService,
    private toastService: AlconToastMessageService,
    private dialog: MatDialog
  ){
    this.loaderSubscription = this.iolModelPreferencesService.loaderSubject.subscribe((data: boolean) => {
      this.isLoader = data;
    });


  }

  ngOnInit(): void {
    this.iolModelPreferencesService.getAllLenses()
  }

  save(save: boolean){
    if(save){
      this.isLoader = true;
      let payload: hiddenPayload[] = [];
      let iols = this.iolModelPreferencesService.updatedIOLSubject.getValue();
      let hiddenLens = [];
      let typelens: hiddenPayload[] = [];
      iols.manufacturer.forEach(manufacturer => {
        typelens = [];
        manufacturer.type.forEach(type => {
          hiddenLens = type.lens.filter(x=> !x.isVisible);
            if(type.lens.length === hiddenLens.length){
              typelens.push({
                  manufacturer: manufacturer.name,
                  type: type.name,
                  model: '*'
                })
            }else if(hiddenLens.length > 0 ){
                hiddenLens.forEach(lens => {
                typelens.push({
                  manufacturer: manufacturer.name,
                  type: type.name,
                  model: lens.name
                })
              });
            }

        });

        //To test if all the type is selected under the manufacturere
          if(typelens.filter(x => x.model === '*').length=== manufacturer.type.length){
            payload.push({
              manufacturer: manufacturer.name,
              type: '*',
              model: '*'
            })
          }else if(typelens.length > 0){
            payload = [...payload, ...typelens];
          }

          //To test if all the manufacturere checked
          if(payload.filter(x => x.type === '*' && x.model === '*').length === iols.manufacturer.length){
            payload = [{
              manufacturer: '*',
              type: '*',
              model: '*'
            }];
          }
      });
      this.iolModelPreferencesService.savePreferencesAPI(payload)
      .subscribe({
          next: (res: any) => 
          {
            this.showToastMessage("Changes saved", "Success");
            this.iolModelPreferencesService.removeSelectedIOLs();
            this.iolModelPreferencesService.getAllLenses()
            this.iolModelPreferencesService.isModelSelected.next(false);
          },
          error: (err: any) => 
          {
            this.isLoader = false;
            this.showToastMessage("Error in Saving", "Error");
          }
       });
    }else{
      this.discardChanges();
    }
  }

  showToastMessage(message: string, type: string){
    this.toastService.showToastMsg(
      message,
      "",
      '',
      type,
      type.toLocaleLowerCase(),
      15000
      );
  }
  openCustomModal(){
    const dialogData:customDialog = {
      type: '',
      icon: "assets/alert-triangle.svg",
      title: "Are you sure you want to discard changes?",
      text:  "If you proceed, the previous settings will be restored.",
      buttonText: {
        primary: "Yes, discard changes",
        secondary: "No, return",
      }
    }
    const dialogRef = this.dialog.open(CustomDialogComponent,{
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      data: dialogData,
    });
    return dialogRef;
  } 
  discardChanges(){
    this.openCustomModal().afterClosed().subscribe((value: dialogAction) => {
      if(value == dialogAction.primary) {
        this.iolModelPreferencesService.removeSelectedIOLs();
        this.iolModelPreferencesService.updateIols(this.iolModelPreferencesService.iolsSubject.getValue());
        this.showToastMessage("Changes discarded", "Warning");
        this.iolModelPreferencesService.isModelSelected.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

  setNoSearchResult(event: boolean, visible: boolean){
    if(visible){
      this.noVisibleSearchResult = event;
    }else{
      this.noHiddenSearchResult = event;
    }

    this.noHiddenSearchResult && this.noVisibleSearchResult ? this.showNoResult = true : this.showNoResult = false;
   
  }
}
