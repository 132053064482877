<div class="alcon-asc-settings-model-container">
    <div class="d-flex justify-content-end">
        <mat-icon
        (click)="closePopup()"
        aria-hidden="false"
        aria-label="Close Model"
        fontIcon="close"
        class="alc-asc-settings-close"
        ></mat-icon>
    </div>
    <div class="tittle-container">
        <span class="title">ASC settings</span>
        <span class="sub-title">{{data.ascName}}</span>
    </div>
    <form [formGroup]="form">

    <div class="asc-settings-content d-flex flex-column">
        <div class="d-flex justify-content-between">
            <alcon-toggle-switch       
            [hideButtonStateLabel]="true"
            label="Arrival time" 
            [selected]="data.isAscSettingsConfigured"
            [customClass]="toggleType.shortAndLabelBold"
            (selectionChanged)="showArrivalTimeSelection($event)">
        </alcon-toggle-switch>
        <div  *ngIf="data.isAscSettingsConfigured" class="arrival-time-other-checkbox-container d-flex align-items-center">
            <mat-checkbox (change)="toggleShowOtherPatient($event.checked)"  color="primary" class="alcon-arrival-time-other-checkbox" />
             <span class="arrival-time-other-label">Arrival time for first patient different to other patients</span>
        </div>
        </div>
       
        <div  *ngIf="data.isAscSettingsConfigured" class="arrival-time-setting-container d-flex align-items-center justify-content-between">
            <div class="set-time-component-container d-flex flex-column align-items-start">
                <span  class="set-time-component-label">{{arrivalTimeLabelMessage}}</span>
                <div class="set-time-component">
                    <app-alcon-time-adjustor [increment]="incrementValue" formControlName="timeAdjustorFirstPatient"></app-alcon-time-adjustor>
                </div>
                <div
                *ngIf="getForm()['timeAdjustorFirstPatient'].errors"
                class="alc_input-error"
              >Enter a value between 0 and 180.
            </div>
            </div> 
            <div  *ngIf="showOtherPatient" class="set-time-component-container d-flex flex-column align-items-start">
                <span class="set-time-component-label">Arrival time other patients</span>
                <div class="set-time-component">
                    <app-alcon-time-adjustor [increment]="incrementValue" formControlName="timeAdjustorOtherPatients"></app-alcon-time-adjustor>
                </div>
                <div
                *ngIf="getForm()['timeAdjustorOtherPatients'].errors"
                class="alc_input-error"
              >Enter a value between 0 and 180.
            </div>
            </div> 
        </div>

    </div>
    <div class="alc_form-actions alc_form_save_bottom">
        <button type="button" class="alc_button alc_button--secondary" (click)="closePopup()">Cancel</button>
        <button type="submit" (click)="submit()" class="alc_button alc_button--primary" [disabled]="!(form.valid && data.isAscSettingsConfigured)">Save</button>
    </div>
    </form>
   
</div>