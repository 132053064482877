import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { ClinicService } from './shared/clinic.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { UserService } from './shared/user.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../environments/environment';
import { IdleService } from './shared/service/idle.service';
import { LoaderService } from './shared/service/loader.service';
import { CustomIconService } from './shared/custom-icon.service';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { SharedService } from './shared/service/shared.service';
import { TealiumUtagService } from './tealium/utag.service';
import { adiStorageKey, clinicConnectConfig, okta2FaAcrConfig } from './shared/constants/auth.constant';
import { filter, map, mergeMap, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RedirectService } from './shared/redirect.service';
import { InitService } from './shared/service/init.service';
import { SessionStorageService } from './shared/session-storage.service';
import { UtilityService } from './shared/service/utility.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [TealiumUtagService]
})
export class AppComponent implements OnInit {
  title = 'angular-app';
  facilities: any = [];
  someSubscription: any;
  AccountFacilityID: string = '';
  headerEndPoint = environment.headerApiEndPoint;
  headerSource = environment.headerSource;
  isAuthenticated: any;
  isAccountLoaded = false;
  isRouteOutsideAdi = false;

  constructor(
    public service: AuthService,
    public userService: UserService,
    public clinicservice: ClinicService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public authStateService: OktaAuthStateService,
    public dialog: MatDialog,
    private idleService: IdleService,
    public loaderService: LoaderService,
    private customIconService: CustomIconService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private tealium: TealiumUtagService,
    private destroyRef: DestroyRef,
    private redirectService: RedirectService,
    private initService: InitService,
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService
  ) {
      this.tealium.setConfig({
        account: environment.TEALIUM_ACCOUNT,
        profile: environment.TEALIUM_PROFILE,
        environment: environment.TEALIUM_ENV,
      });
      this.customIconService.registerIcons();
      this.redirectService.redirectIfNeeded();


    this.sharedService.facilityDDRefreshSubject
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(()=>{
      this.reloadPage();
    })
    this.getRouteData();
  }
  async checkAuthentication(){
      if(!this.service.hasAdiHomeToken() && !window.location.host.includes('localhost')){
        window.location.href = `${window.location.origin}`;
      } else if(!(await this.service.isAuthenticated())){
          await this.oktaAuth.signInWithRedirect({acrValues: okta2FaAcrConfig});
      } else{
        this.isAuthenticated = true;
        this.idleService.startIdleWatching();
      } 
  }
  
  setPermission(event: any){
    this.service.entitlementValue$.next(true);
    this.initService.setConfig(event);
    if (event && event.associatedAccounts && event.associatedAccounts.length > 0){
      this.isAccountLoaded = true;
    }
  }

  async ngOnInit() {
    this.redirectService.redirectIfNeeded();
    //For Adi Header
    if(window.location.host.includes('localhost')){
      if(!localStorage.getItem(adiStorageKey.lang)){
        localStorage.setItem(adiStorageKey.lang,'en_US');
      }
      if(!localStorage.getItem(adiStorageKey.SELECTED_ACCOUNT_ID)){
        localStorage.setItem(adiStorageKey.SELECTED_ACCOUNT_ID,'0100000010');
      }
      if(!localStorage.getItem(adiStorageKey.ADI_TRACE_ID)){
        localStorage.setItem(adiStorageKey.ADI_TRACE_ID,'0eec1188-571f-410d-84b0-463735433656');
      }
    }
    this.customIconService.registerIcons();
    this.route.queryParams.pipe(
      (take(4))
    ).subscribe(queryParams => {
      if(queryParams['sapId']){
        let quertSAPId = this.utilityService.formatSapId(queryParams['sapId']);
        let selectedAccount = this.utilityService.formatSapId(localStorage.getItem(adiStorageKey.SELECTED_ACCOUNT_ID));
        let changedSapId = this.utilityService.formatSapId(this.sessionStorageService.getItem(clinicConnectConfig.cc_changed_sapId));
      
        if(selectedAccount !== quertSAPId && !changedSapId){
           localStorage.setItem(adiStorageKey.SELECTED_ACCOUNT_ID, quertSAPId);
           this.sessionStorageService.setItem(clinicConnectConfig.cc_changed_sapId, quertSAPId);
           this.reloadPage();
        }
        this.sharedService.updateParamChange(true);
      }
    });

    this.tealium.view({ event_name: 'init' });
  }

  reloadPage() {
    window.location.reload();
 }

  getRouteData(){
    const routerSubscribe = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe((data) =>{
      this.isRouteOutsideAdi = data?.["isAdiOutside"] ? true : false;
        if(!this.isAuthenticated && !this.isRouteOutsideAdi){
          this.checkAuthentication();
          routerSubscribe.unsubscribe();
        }
    
    });
  }
  
}
