import { Component, Inject } from '@angular/core';
import { AlconInlinePdfViewerComponent } from '../alcon-inline-pdf-viewer/alcon-inline-pdf-viewer.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { HyphenatePipe } from '../../pipe/hyphenate.pipe';
import { UtilityService } from '../../service/utility.service';

export interface alconPdfConfig{
  src:string;
  name: string;
}

@Component({
  selector: 'alcon-pdf-viewer-model',
  standalone: true,
  imports: [
    CommonModule,
    AlconInlinePdfViewerComponent,
    MatIcon,
    HyphenatePipe
  ],
  templateUrl: './alcon-pdf-viewer-model.component.html',
  styleUrl: './alcon-pdf-viewer-model.component.scss'
})
export class AlconPdfViewerModelComponent {
  
  constructor(
    public dialogRef: MatDialogRef<AlconPdfViewerModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: alconPdfConfig,
    private utilityService: UtilityService
  ){}
  
  close(){
    this.dialogRef.close();
  }

  downloadDoc(){
    this.utilityService.downloadDocument(this.data.src)
  }

}
