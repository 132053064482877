<div class="iol-model-component-container">
    <div class="iol-model-header d-flex justify-content-between">
        <p>{{title}}</p>
      <button class="alc_button alc_button--secondary" [disabled]="!iols || !iols.anyChildrenChecked" (click)="moveIols()">{{btnText}}</button>
    </div>
    <ng-container *ngIf="iols?.manufacturer && iols.manufacturer.length > 0; else noIOLs">
      <div class="iols" >
          <div class="d-flex justify-content-between" >
              <div class="toggle-container" (click)="onExpandClick()">
                  <span *ngIf="allExpanded">
                    <span class="toggle-text">Collapse All</span><mat-icon class="toggle-icon">unfold_less</mat-icon>
                  </span>
                  <span *ngIf="!allExpanded">
                    <span class="toggle-text">Expand All</span><mat-icon class="toggle-icon">unfold_more</mat-icon>
                  </span>
                </div>
                <div>
                  Select All  <span *ngIf="selectedModel"> ({{selectedModel}} IOL{{selectedModel > 1 ? 's' :''}} selected)</span>
                  <mat-checkbox color="primary" class="alcon-check-box"
                  (change)="$event ? updateCheckStatus() : null"
                  [checked]="iols.checked"
                  [indeterminate]="!iols.checked && iols.anyChildrenChecked"
                  (click)="selectCheckBox($event)"></mat-checkbox>
                </div>
          </div>
          <mat-accordion multi>
              <mat-expansion-panel class="outer-expansion" [ngClass]="{ 'selected': manufact.checked}" *ngFor="let manufact of iols?.manufacturer" >
                  <mat-expansion-panel-header >
                      <mat-panel-title>
                      {{manufact.name}}{{' ('+manufact.lensCount +')'}}
                      </mat-panel-title>
                      <mat-panel-description>
                      <mat-checkbox color="primary" class="alcon-check-box" 
                      [checked]="manufact.checked"
                      (change)="$event ? updateCheckStatus(manufact) : null"
                      [indeterminate]="!manufact.checked && manufact.anyChildrenChecked"
                      (click)="selectCheckBox($event)"></mat-checkbox>
                      </mat-panel-description>
                  </mat-expansion-panel-header>
                  <!-- <ng-template matExpansionPanelContent> -->
                      <mat-accordion multi>
                          <mat-expansion-panel class="inner-expansion" *ngFor="let type of manufact.type" [ngClass]="{ 'selected': type.checked}">
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                              {{type.name}}{{' ('+type.lens.length +')'}}
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-checkbox color="primary" class="alcon-check-box"
                                (change)="$event ? updateCheckStatus(manufact, type) : null"
                                [checked]="type.checked"
                                [indeterminate]="!type.checked && type.anyChildrenChecked"
                                (click)="selectCheckBox($event)"></mat-checkbox>
                                </mat-panel-description>
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>

                            <div class="iol-container  d-flex justify-content-between" *ngFor="let lens of type.lens" [ngClass]="{ 'selected': lens.checked}">
                              <span class="lens-name">{{lens.name}}<span class="lens-description">{{lens.description}}</span> </span>
                              <mat-checkbox color="primary" class="alcon-check-box" (click)="selectCheckBox($event)"
                              (change)="$event ? updateCheckStatus(manufact, type, lens) : null"
                              [checked]="lens.checked"
                              ></mat-checkbox> </div>
                           </ng-template>
                          </mat-expansion-panel>
                      </mat-accordion>
            <!-- </ng-template> -->

              </mat-expansion-panel>
          </mat-accordion>
      </div>
    </ng-container>
    <ng-template #noIOLs>
      <div class="no-iol-container">
        <p *ngIf="!noSearchResult">{{ visible ? 'Select a model, model type or manufacturer and make them visible' : 'Select a model, model type or manufacturer and hide them'}}</p>
        <p *ngIf="noSearchResult">No results</p>
      </div>
     </ng-template> 
</div>
