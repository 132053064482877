import { configAlwaysVisibleItem } from "../model/configuration-model";

// Define the enum with the same keys as the surgeriesDashboardFields object
export enum SurgeryDashboardField {
    Name = 'name',
    Dob = 'dob',
    Dos = 'dos',
    SurgeryType = 'surgeryType',
    Surgeon = 'surgeon',
    Eye = 'eye',
    SetFor = 'setFor',
    Meds = 'meds',
    Supplies = 'supplies',
    PatientComments = 'patientComments',
    RecentChanges = 'recentChanges',
    SurgeryStatus = 'SurgeryStatus',
    Verification = 'verification',
    Femto = 'femto',
    ORA = 'ORA',
    PrimaryIOL = 'primaryIOL',
    IOLTable = 'IOLTable',
    Actions = 'actions'
}

export const surgeryDashboardOptionalFields = [
    {label: 'Eye', value: 'Eg. L / 1st', field: SurgeryDashboardField.Eye},
    {label: 'Set for', value: 'Eg. Distance', field: SurgeryDashboardField.SetFor},
    {label: 'Meds', value: 'Eg. 1x', field: SurgeryDashboardField.Meds},
    {label: 'Supplies', value: '', field: SurgeryDashboardField.Supplies},
    {label: 'Patient comments', value: '', field: SurgeryDashboardField.PatientComments},
    {label: 'Recent changes indicator', value: '', field: SurgeryDashboardField.RecentChanges},
    {label: 'Surgery status', value: '', field: SurgeryDashboardField.SurgeryStatus},
    {label: 'Verification', value: '', field: SurgeryDashboardField.Verification},
    {label: 'Femto', value: '', field: SurgeryDashboardField.Femto},
    {label: 'ORA', value: '', field: SurgeryDashboardField.ORA},
    {label: 'Primary IOL', value: '', field: SurgeryDashboardField.PrimaryIOL},
    {label: 'IOL table', value: '', field: SurgeryDashboardField.IOLTable}
  ];

export const surgeryDashboardMandatoryFields: configAlwaysVisibleItem[]  = [
    { field: 'Patient name', example: 'Eg. Ramos, Jhon'},
    {field: 'Date of Birth', example: 'Eg. 01/01/1975'},
    {field: 'Date of Surgery', example: 'Eg. 01/01/1975'},
    {field: 'Surgery type', example: 'Eg. Cataract'},
    {field: 'Surgeon', example: 'Eg. Dr. Steven Skye'
  }];

  export const dashboardViewType = 'DASHBOARD_VIEW';