import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {  MatExpansionModule } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { configAlwaysVisibleItem } from '../../model/configuration-model';
import { AlconToggleSwitchComponent, toggleType } from '../alcon-toggle-switch/alcon-toggle-switch.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { AlconTimeAdjustorComponent } from '../alcon-arrival-time-adjustor/alcon-time-adjuster.component';
import { ascSettingsConstants } from './alcon-asc-settings-constant';

@Component({
  selector: 'app-alcon-asc-settings-model',
  standalone: true,
  imports: [
    MatIcon,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AlconToggleSwitchComponent,
    MatCheckbox,
    AlconTimeAdjustorComponent

  ],
  templateUrl: './alcon-asc-settings-model.component.html',
  styleUrl: './alcon-asc-settings-model.component.scss'
})
export class AlconASCSettingsModelComponent {
  toggleType = toggleType;
  showOtherPatient = false
  form: FormGroup;
  incrementValue  = ascSettingsConstants.incrementValue
  arrivalTimeLabelMessage = 'Arrival time for all patients'

  constructor(
    public dialogRef: MatDialogRef<AlconASCSettingsModelComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    this.form = this.fb.group({
      timeAdjustorFirstPatient: [ascSettingsConstants.defaultArrivalTime, [Validators.required, Validators.max(ascSettingsConstants.maxArrivalTime)]],
      timeAdjustorOtherPatients: [ascSettingsConstants.defaultArrivalTime, [Validators.required, Validators.max(ascSettingsConstants.maxArrivalTime)]]

    }); 
   ;
  }
  ngOnInit(): void
  {
    //if this.data.ascTimeSettings is null I have not configured the ASC settings yet, so  toggle switch is off
    this.preFillTimeData()
  }

  preFillTimeData(){

    let prefillDataFirstPatient = this.data.ascTimeSettings.firstPatientArrivalTime ;
    if(prefillDataFirstPatient && prefillDataFirstPatient !== ''){
      this.form.patchValue({
        timeAdjustorFirstPatient: +prefillDataFirstPatient
       });
    }

    let prefillDataOtherPatient = this.data.ascTimeSettings.standardPatientArrivalTime ;
    if(prefillDataOtherPatient && prefillDataOtherPatient !== ''){
      this.form.patchValue({
        timeAdjustorOtherPatients: +prefillDataOtherPatient
       });
    }
  }
  closePopup()
  {
    this.dialogRef.close();
  }
  toggleShowOtherPatient(checked: boolean) {
    this.showOtherPatient = !this.showOtherPatient
    this.arrivalTimeLabelMessage = (this.showOtherPatient)?"Arrival time for first patient":"Arrival time for all patients"
  }

  submit(){  
    let prefillDataOtherPatient = this.data.ascTimeSettings.standardPatientArrivalTime ;
    let timeSettings =  {
      "firstPatientArrivalTime": this.form.value.timeAdjustorFirstPatient.toString(),
      "standardPatientArrivalTime":(this.showOtherPatient)?this.form.value.timeAdjustorOtherPatients.toString():((prefillDataOtherPatient && prefillDataOtherPatient !== '') ? prefillDataOtherPatient:null )
    };
    this.dialogRef.close(timeSettings);
  }

  
  showArrivalTimeSelection($event: boolean){
    this.data.isAscSettingsConfigured = !this.data.isAscSettingsConfigured
  }
  getForm() {
    return this.form.controls;
  }

}
