import { Injectable } from '@angular/core';
import { GraphqlService } from './graphql.service';

@Injectable({
  providedIn: 'root'
})
export class PatientGraphqlService {

  constructor(private graphqlService: GraphqlService) { }

   getPatientDetailsGraphQuery(config:any){
    let argString = "";
    if(config.limit !== undefined || config.limit !== null){
      argString += "limit: " + config.limit + " "
    }
 
    if(config.offset !== undefined || config.offset !== null){
      argString += "offset: " + config.offset + " "
    }
 
    if(config.patientId !== undefined || config.patientId !== null){
      argString += `where: {_text: {field: "patient.patientId", op: EQ, value: "${config.patientId}"}}`
    }
    const query = `query MyQuery {
      patientResponse(${argString}) {
        totalCount
        patients {
          birthDate
          firstName
          middleName
          lastName
          prefix
          gender
          email
          mrn
          languageDisplay
          languageCode
          practiceId
          additionalInfo {
            comments
            diabetesStatus
            allergyOther
            translatorNeeded
            patientTimeOfDayPreference
            alternateContactName
            alternatePhoneNumber
            phoneNumberCountryCode
            signLanguage
          }
          phone
          patientId
          surgeries {
            surgeonId
            surgeryId
            updatedAt
            surgeryData {
              consentForm {
                status
              }
              preVerificationForm  {
                createdAt
                createdBy
              }
              applicationMeta {
                documents {
                  documentId
                }
              }
              surgeonId
              surgeryDate
              surgeryName
              surgerySite
              updatedAt
              procedure
              complexity
              clearance
              eye
              setFor
              lensx
              ora
              surgeryComment
              generalAnesthesia
              isDeleted
              status
              internalNotes
              postopMeds
              otherMeds
              firstOrSecondEye
              practiceOrg
              ascId
              clinicId
              patientId
              supplies {
                istent
                hydrus
                trypanBlue
                malyuginRings
                omni
                kdb
                gatt
                ctr
                irisHooks
                other
              }
              waitlistInterest
              otherSupplies
              includeSecondPrimary
              iols {
                diopter
                iolManufacturer
                lensPriority
                lensModel
                lensType
                implantationAxis
              }
            }
            iolSurgeonPreferences {
              brand
              diopter
              lensPreference
              lensType
              implantationAxis
              manufacturer
              model
            }
            surgeon {
              firstName
              lastName
              email
              id
              prefix
            }
            asc {
              name
              practiceOrg
            }
            clinic {
              name
              practiceOrg
            }
          }
        }
      }
    }
    `
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
  return this.graphqlService.callgraphApi(objStringFy);
  }
 
  // Existing patient new surgery and Existing Patient list component
  searchPatientGraphQuery (config: any) {
    let argString = "";
   argString += `where: {_and:  
       [ ${this.getWherePatientQuery(config)} ]}
        sort: {field: "patient.firstName", direction: ASC}`
    
    const query = `query MyQuery {
      patientResponse(${argString}) {
        totalCount
        patients {
          birthDate
          createdAt
          createdBy
          email
          firstName
          gender
          lastName
          mrn
          patientId
          practiceId
          phone
        }
      }
    }
    `
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
  return this.graphqlService.callgraphApi(objStringFy);
  }

  // Patient component
  searchPatientMrnGraphQuery(config:any) {
    let argString = "";
    if((config.patientMRN !== undefined && config.patientMRN !== null) || (config.practiceOrg !== undefined && config.practiceOrg !== null)){
      argString += `where: {_and:  
        [
        {_text: {field: "patient.mrn", op: IEQ, value: "${config.patientMRN.trim()}"}},
        {_text: {field: "patient.practiceId", op: EQ, value: "${config.practiceOrg}"}}
    ]}`
    }
    const query = `query MyQuery {
      patientResponse(${argString}) {
        totalCount
        patients {
          birthDate
      createdAt
      createdBy
      email
      firstName
      gender
      lastName
      mrn
      patientId
      practiceId
      phone
        }
      }
    }`
    const obj = {
      query: query,
      operationName: 'MyQuery'
    }
    const objStringFy = JSON.stringify(
      obj
      );
  return this.graphqlService.callgraphApi(objStringFy);
  }

  getWherePatientQuery(config: any) {
    let patientWhereString = '';
    if(config.patientId) {
      patientWhereString += `{_text: {field: "patient.patientId", op: LIKE, value: "${config.patientId.trim()}"}},`
    }
    if(config.firstName) {
      patientWhereString += `{_text: {field: "patient.firstName", op: ILIKE, value: "${config.firstName.trim()}"}},`
    }
    if(config.lastName) {
      patientWhereString += `{_text: {field: "patient.lastName", op: ILIKE, value: "${config.lastName.trim()}"}},`
    }
    if(config.dateOfBirth) {
      patientWhereString += `{_text: {field: "patient.birthDate", op: LIKE, value: "${config.dateOfBirth}"}},`
           
    }
    if(config.practiceOrg) {
      patientWhereString += `{_text: {field: "patient.practiceId", op: IN, value: "${config.practiceOrg}"}},`
    }

    if(config.patientMRN) {
       patientWhereString += `{_text: {field: "patient.mrn", op: ILIKE, value: "${config.patientMRN.trim()}"}},`
    }

    if(config.gender) {
      patientWhereString += `{_text: {field: "patient.gender", op: LIKE, value: "${config.gender}"}},`
    }

    return patientWhereString;

  }

}
