import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum toggleType {
  'short' = 'short',
  'shortAndLabelBold' = 'short--bold-label'
}
@Component({
  selector: 'alcon-toggle-switch',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alcon-toggle-switch.component.html',
  styleUrl: './alcon-toggle-switch.component.scss'
})
export class AlconToggleSwitchComponent {

  @Input() label!: string;
  @Input() selected!: boolean;
  @Input() hideButtonStateLabel: boolean = false;
  @Input() scaleValue: number = 1;
  @Input() customClass: string = '';

  @Output() selectionChanged = new EventEmitter();

  public onText = "Yes";
  public offText = "No";

  public toggleSwitch(){
    this.selected = !this.selected;
    this.selectionChanged.emit(this.selected);
  }
}
