<page-header [pageTitle]="'Admin portal'"></page-header>
<tab-navigation [menuItems]="menus[1]['admins']"></tab-navigation>
<app-iol-preferences-filter (save)="save($event)"
    ></app-iol-preferences-filter>
<div class="container iol-model-conatainer justify-content-between" [ngClass]="showNoResult ? 'hide': 'd-flex'">
    <app-iol-models class="iol-model-column"
        [title]="'Visible models'"
        [btnText]="'Mark as hidden'"
        [visible]="true"
        (noSearchResultEvent)="setNoSearchResult($event, true)"
    ></app-iol-models>
    <app-iol-models class="iol-model-column"
    [title]="'Hidden models'"
    [btnText]="'Make visible'"
    [visible]="false"
    (noSearchResultEvent)="setNoSearchResult($event, false)"
    ></app-iol-models>
</div>
<div class="no-result-container justify-content-center" [ngClass]="!showNoResult ? 'hide': 'd-flex'">
    No results found
</div>

<alc-section-loader *ngIf="isLoader"></alc-section-loader>
