import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";

  @Component({
    selector: 'app-alcon-time-adjustor',
    standalone: true,
    imports: [
      CommonModule,
      MatIcon,
      FormsModule
    ],
    templateUrl: './alcon-time-adjuster.component.html',
    styleUrl: './alcon-time-adjuster.component.scss',
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        multi:true,
        useExisting: AlconTimeAdjustorComponent
      },
      {
        provide: NG_VALIDATORS,
        multi: true,
        useExisting: AlconTimeAdjustorComponent
      }
    ]
  })

  export class AlconTimeAdjustorComponent implements ControlValueAccessor, Validator {

    quantity = 0;
  
    @Input() increment: number = 5;
    
    onChange = (quantity:number) => {};
  
    onTouched = () => {};
  
    touched = false;
  
    disabled = false;
  
    incrementAction() {
      this.markAsTouched();
      if (!this.disabled) {
        this.quantity+= this.increment;
        this.onChange(this.quantity);
      }
    }
  
    decrementAction() {
      this.markAsTouched();
      if (!this.disabled) {
        this.quantity-= this.increment;
        this.onChange(this.quantity);
      }
    }
  
    writeValue(quantity: number) {
      this.quantity = quantity;
    }
  
    registerOnChange(onChange: any) {
      this.onChange = onChange;
    }
  
    registerOnTouched(onTouched: any) {
      this.onTouched = onTouched;
    }
  
    markAsTouched() {
      if (!this.touched) {
        this.onTouched();
        this.touched = true;
      }
    }
  
    setDisabledState(disabled: boolean) {
      this.disabled = disabled;
    }

    onQuantityChange(newValue: number) {
      this.quantity  = newValue
      this.onChange(this.quantity);
    }
    validate(control: AbstractControl): ValidationErrors | null {
      const quantity = control.value;
      if (quantity < 0) {
        return {
          mustBePositive: {
            quantity
          }
        };
      }
      return null
    }
  }