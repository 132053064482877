import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { OKTA_AUTH, OktaAuthConfigService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { okta1FaAcrConfig, okta2faErrorCode } from 'src/app/shared/constants/auth.constant';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-okta-callback',
  templateUrl: './custom-okta-callback.component.html',
  styleUrl: './custom-okta-callback.component.scss'
})
export class CustomOktaCallbackComponent implements OnInit {
  error?: string;
  headerEndPoint = environment.headerApiEndPoint;
  headerSource = environment.headerSource;
  constructor(
    private configService: OktaAuthConfigService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private navigationService: NavigationService,
    @Optional() private injector?: Injector
  ) {}

  async ngOnInit(): Promise<void> {
    const config = this.configService.getConfig();
    if (!config) {
      throw new Error('Okta config is not provided');
    }
    try {
      // Parse code or tokens from the URL, store tokens in the TokenManager, and redirect back to the originalUri
      await this.oktaAuth.handleLoginRedirect();
    } catch (e) {
      // Callback from social IDP. Show custom login page to continue.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Supports auth-js v5 & v6-7
      const isInteractionRequiredError = this.oktaAuth.isInteractionRequiredError || this.oktaAuth.idx.isInteractionRequiredError;
      if (isInteractionRequiredError(e) && this.injector) {
        const { onAuthResume, onAuthRequired } = config;
        const callbackFn = onAuthResume || onAuthRequired;
        if (callbackFn) {
          callbackFn(this.oktaAuth, this.injector);
          return;
        }
      }
      this.error = (e as Error).toString();
      if(this.error?.toLowerCase().includes("auth flow has already completed")){
        this.navigationService.navigateTo(['surgeries'])
      }
      else if((e as { errorCode: string }).errorCode === okta2faErrorCode){
        this.oktaAuth.signInWithRedirect({acrValues: okta1FaAcrConfig})
      }
    }
  }

  isNotAssigned(): boolean {
    return this.error?.toLowerCase().includes('not assigned') ?? false;
  }
}

