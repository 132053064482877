import { Injectable } from '@angular/core';
import { PatientAndSurgeryCreationEvents, PatientStatus, Surgery, SurgeryVerificationStatus, TooltipObject } from '../model/surgery';
import { HistoryLog, LogDelta } from './alcon-history-log/Model/alcon-history-log-model';
import * as historyLog from '../components/modal-dialogs/alcon-history-log/alcon-history-log-field-mapping.json';
import { AuthService } from './auth.service';
import { surgeryType } from './model/surgery.model';
import { recentChangesDaysLimitFromToday } from './constants/constants';
import { patientSurgery } from '../pages/patient/patient-details/patient-details.model';
import { UtilityService } from './service/utility.service';

@Injectable({
  providedIn: 'root'
})
export class PatientReadinessService {
  newLine: string = '\n  ';
  bullet: string = '\u2022   ';
  historyLog = JSON.parse(JSON.stringify(historyLog));

  constructor(
    private utilityService: UtilityService
  ){}
  
  getMandatoryFieldsObject(surgery: Surgery): TooltipObject[]{
    const common = [
        { name: 'First Name', value: surgery.patient.firstName},
        { name: 'Last Name', value: surgery.patient.lastName},
        { name: 'Date of Birth', value: surgery.patient.birthDate},
        { name: 'Patient ID', value: surgery.patient.patientId},
        { name: 'Gender', value: surgery.patient.gender},
        { name: 'Phone Number', value: surgery.patient.phone},
        { name: 'Surgeon', value: surgery.surgeon?.fullName},
        { name: 'Procedure', value: surgery.surgeryData.procedure},
        { name: 'Surgery date', value: surgery.surgeryData.surgeryDate},
        { name: 'Case Complexity', value: surgery.surgeryData.complexity},
      ];
   
    if(surgery.surgeryData.procedure === surgeryType.catract || surgery.surgeryData.procedure === surgeryType.exchange){
      const cataractSpecs =[
        { name: 'Eye', value: surgery.surgeryData.eye},
        { name: 'Set For', value: surgery.surgeryData.setFor},
        { name: 'Post Op Medications', value: surgery.surgeryData.postopMeds},
        { name: 'Primary IOL', value: this.checkIsIOLMissing(surgery)} 
      ];
      if(surgery.surgeryData.procedure === surgeryType.catract){
        cataractSpecs.push({ name: '1st or 2nd', value: surgery.surgeryData.firstOrSecondEye});
      }
    
      return [...common,...cataractSpecs]
    }
    return [...common]
  }

  getIncompleteDetails(surgery: Surgery): string{
    let str = '';
    const fieldsObj = this.getMandatoryFieldsObject(surgery);
    fieldsObj?.forEach((x: TooltipObject) => {
      if(x.value === undefined || x.value === '' || x.value === null || (x.name === 'Primary IOL' && x.value === true)){
        str += this.bullet + x.name + this.newLine;
      }
    })
    if(!surgery.surgeryData.preVerificationForm){
      str += this.bullet + 'Pre-verification Form' + this.newLine;
    }
    str = (str !== '') ? 'Missing fields:' + this.newLine + str : str;
    return str || '';
  }

  getPatientReadinessTooltip(surgery: Surgery): string{

    switch (this.getSurgeryVerificationStatus(surgery)){
      case SurgeryVerificationStatus.missingInfo:
        return  this.getIncompleteDetails(surgery);
      case SurgeryVerificationStatus.completed:
        return 'No Missing fields!';
      case SurgeryVerificationStatus.pending:
        return 'Missing fields:' + this.newLine + this.bullet + 'Pre-verification Form';
      default:
        return '';
    }
   
  }

  getSurgeryVerificationStatusToolTipMessage(surgery: Surgery): string{

    switch (this.getSurgeryVerificationStatus(surgery)){
      case SurgeryVerificationStatus.missingInfo:
        return 'Verification not ready to be signed, missing patient or surgery info.';
      case SurgeryVerificationStatus.completed:
        return 'Verification done';
      case SurgeryVerificationStatus.pending:
        return  'Verification missing. Start verification from the menu.';
      default:
        return '';
    }
  }
 
  getSurgeryVerificationStatus(surgery: Surgery):SurgeryVerificationStatus{
    if (surgery.surgeryData.status === PatientStatus.DRAFT) {
         return SurgeryVerificationStatus.missingInfo;
    }
     else 
    {
    //if object preVerificationForm existing means surgery verification is submitted. So set status as Done. 
     if (surgery.surgeryData.preVerificationForm){
      return SurgeryVerificationStatus.completed
     }
     else {
      return  SurgeryVerificationStatus.pending;
     }
    }
  }
  
  getPatientSurgeryTooltip(surgery: Surgery): string {
    let comments: string = '';
    if(surgery?.patient?.additionalInfo?.comments && surgery?.patient?.additionalInfo?.comments?.trim() !== '') {
      comments = 'Patient comments: '  + comments + surgery.patient.additionalInfo.comments?.trim() + this.newLine;
    }
    if(surgery?.surgeryData?.surgeryComment && surgery?.surgeryData?.surgeryComment?.trim() !== '') {
      comments = comments + this.newLine + 'Surgery comments: ' + surgery.surgeryData.surgeryComment?.trim(); 
    }
    if(comments === '') { return 'No comments'; }
    return comments;
  }

  checkIsIOLMissing(obj: Surgery | patientSurgery){
    let primaryIol;
    if(obj.surgeryData.iols && Array.isArray(obj.surgeryData.iols)) {
        primaryIol = obj.surgeryData.iols.find((iol: any) => {
        return iol?.lensPriority === 'PRI';
      });
    }  
    if(primaryIol && this.anyMissedMandatoryField(primaryIol)){
      return false;
    }
    return true;
  }

  anyMissedMandatoryField(primaryIol: any){
    delete primaryIol["implantationAxis"];
    return Object.values(primaryIol).every(value => value !== null && value !== undefined && value !== '');
  }
  getRecentChangesTooltipContent(data: HistoryLog[]) {
    const noOfChanges = 2;
    let requiredChanges: string[] = [];
    for(let change of data) {
      if(this.updatedRecently(change.dateTime)) {
        if(change.event && (change.event !== PatientAndSurgeryCreationEvents.patientCreation) &&  (!change.event.includes( PatientAndSurgeryCreationEvents.surgeryCreation))) {
          const eventText = this.utilityService.capitalizeFirstLetter(change.event);
          if(!requiredChanges.includes(eventText)) { requiredChanges.push(eventText); }
        }
        if(change.logDelta && (change.logDelta?.length > 0)) {
          for(let delta of change.logDelta) {
            if(!requiredChanges.includes(delta.field)) { 
              requiredChanges.push(delta.field); 
            }
            if(requiredChanges.length === noOfChanges) { break; }
          }
        }
        if(requiredChanges.length === noOfChanges) { break; }
      } else {
          break;
      }
    }
    let content = '';
    if (requiredChanges.length === 0) {
      content = 'No data changed';
    } else {
      content = 'Recent changes:' + this.newLine;
      requiredChanges.forEach((change: string) => {
        content = content + this.bullet + (this.historyLog[change] ? this.historyLog[change] : change) + this.newLine;
      });
    }
    return content;
  }

  hasRecentChanges(surgery: Surgery): boolean { // to check recent changes in past 7 days
    return this.updatedRecently((surgery.patient.updatedAt)) || this.updatedRecently((surgery.updatedAt));
  }

  updatedRecently(updatedAt?: string | null): boolean { // to check recent changes in past 3 days
    if (!updatedAt) return false;
    const activeDuration = recentChangesDaysLimitFromToday; // 3 days in milli seconds
    const today = new Date().valueOf();
    const _updatedAt = new Date(updatedAt.replace(' ', 'T') + 'Z').valueOf();
    if(((today - _updatedAt) <= activeDuration)) {
      return true;
    } else {
      return false;
    }
  }

}
