import { Time } from "@angular/common";
import { additionalInfo, applicationMeta, patientSurgery } from "../pages/patient/patient-details/patient-details.model";

export interface TooltipObject { 
    value: string | Date | boolean; 
    name: string; 
}
export enum PatientStatus {
    READY = 'Ready',
    DRAFT = 'Draft',
    SCHEDULE = 'Schedule'
}

export enum SurgeryVerificationStatus {
  missingInfo = 'missing info',
  completed = 'Done',
  pending = 'Not done'
}

export enum PatientAndSurgeryCreationEvents {
  patientCreation = 'PATIENT CREATED',
  surgeryCreation = 'SURGERY CREATE'
}

export enum IOLSelectionStatus {
  noIOL = 'No IOLs selected',
  noModelAndDiopter = 'Model and diopter pending',
  noDiopter = 'Diopter pending',
  iOLSelected = 'IOL Selected'
}


// ===========GraphQL implementation starts===============================
export interface SurgeryCounts {
  surgeries: Surgery[];
  totalCount: number;
}

export interface Surgery {
  patient: Patient;
  surgeryData: surgeryDetail;
  surgeryId: string;
  iolSurgeonPreferences?: iolSurgeonPreferences[];
  surgeon: Surgeon;
  asc: Asc;
  clinic: clinic;
  updatedAt?:string;
  applicationMeta: applicationMeta | undefined | null;
}
export interface ConsentFormDetails {
  surgeryId: string;
  firstName: string;
  lastName: string;
  patientId: string;
  birthDate: Date;
  procedure: string;
  eye: string;
  practiceOrg: string;
  ascId: string;
  clinicId: string;
  surgeonPrefix: string | undefined;
  surgeonFirstName: string | undefined;
  surgeonLastName: string | undefined;
  ascName: string | undefined;
  surgeryName?: string | undefined;
  surgerySite?: string | undefined;
}

export interface iolSurgeonPreferences {
  brand: string
  diopter: string
  lensPreference: string
  lensType: string
  manufacturer: string
  model: string
  implantationAxis?: string
}

export interface Patient {
  firstName: string;
  lastName: string;
  fullName: string;
  patientId: string;
  birthDate: Date;
  practiceId: string;
  mrn: string;
  gender: string;
  phone: string;
  additionalInfo: additionalInfo;
  middleName?: string;
  prefix: string;
  email: string | null;
  languageDisplay: string; //To-Do
  createdAt: string;
  updatedAt: string | null;
}

export interface surgeryDetail {
  ascId: string;
  ascSapId: string;
  procedure: string;
  clinicId: string;
  surgeryDate: Date;
  eye: string;
  setFor: string;
  postopMeds: string;
  lensx: boolean;
  ora: boolean;
  otherSupplies: string[];
  practiceOrg: string;
  supplies: Supplies;
  consentForm: Consent;
  iols: IOL[];
  status: PatientStatus;
  complexity: string;
  firstOrSecondEye: string;
  surgeryComment: string;
  recentChanges: string[];//To-Do
  generalAnesthesia: boolean;
  waitlistInterest: boolean;
  applicationMeta: applicationMeta | undefined | null;
  createdAt: string;
  updatedAt: string | null;
  otherMeds?: string | undefined | null;
  surgeryName?: string;
  surgerySite?: string;
  preVerificationForm?:preVerificationForm;
  clearance: string;

}

interface preVerificationForm{
  createdAt: string;
  createdBy: string;
}
export interface Supplies {
  istent: boolean;
  hydrus: boolean;
  trypanBlue: boolean;
  malyuginRings: boolean;
  omni: boolean;
  kdb: boolean;
  gatt: boolean;
  ctr: boolean;
  irisHooks: boolean;
  other: boolean;
}

export interface Consent {
  status: string;
  referenceId: string;
}

export interface IOL {
  diopter: string;
  iolManufacturer: string;
  lensPriority: string;
  lensModel: string;
  lensType: string;
  implantationAxis?: string;
}

export interface Surgeon{
    prefix: string;
    firstName: string;
    lastName: string;
    id: string;
    fullName: string;
    preference?: {
      additionalGeneralAnesthesiaTime: number,
      additionalLenxTime: number,
      additionalOraTime: number,
      backupIol: boolean,
      complexSurgeryTime: number,
      oneD: boolean,
      oneTPower: boolean,
      pointFiveD: boolean,
      standardSurgeryTime: number,
      twoTPower: boolean
    }
}

export interface Asc{
  name: string;
  practiceOrg: string;
}
export interface clinic{
  name: string;
}
export const RECORD_PER_PAGE = 10;

export const EYE_LABEL = {
  "L": "Left",
  "R": "Right"
}

export const SURGERY_TYPE_SHORT_LABEL = {
  "Cataract Surgery": "Cataract",
  "Other Surgery": "Other",
  "Exchange Surgery": "Exchange",
}
export interface IncompleteSurgeriesList {
  firstName: string;
  lastName: string;
  date: string;
  type: string;
  surgeonFirstName: string;
  surgeonLastName: string;
  eye: string;
  selected: boolean;
  surgeonPrefix: string;
  surgeryId: string;
  surgeonId: string
}

export interface IncompleteSurgeriesData {
  surgeries: IncompleteSurgeriesList[];
  count: number;
}

export enum SurgeryOperations  {
  complete = 'complete',
  cancel = 'cancel'
}

export interface selectedSurgeriesForProcessing {
  operation: SurgeryOperations,
  data: IncompleteSurgeriesList[]
}

export interface SurgeryUpdatePayload {
  surgeryId: string;
  surgeonId: string;
  status: 'Cancel' | 'Completed';
}

export interface BulkUpdateSurgeryResponse {
  summary?: BulkUpdateSurgeryResponseSummary;
  surgeries?: BulkUpdateSurgeryResponseSurgeryUpdate[];
  implantedIolsLink?: string;
  statusCode: string;
  message: string;
  referenceId?: string;
}

interface BulkUpdateSurgeryResponseSummary {
  requestType: string;
  requestStatus: string;
  totalNumberOfRecords: number;
  successNumberOfRecords: number;
  failedNumberOfRecords: number;
}

interface BulkUpdateSurgeryResponseSurgeryUpdate {
  surgeryData: BulkUpdateSurgeryResponseSurgeryData;
  errors: string[];
  operationStatus: string;
}

interface BulkUpdateSurgeryResponseSurgeryData {
  surgeryId: string;
  surgeonId: string;
  status: string;
}

export interface IncompleteSurgeriesUserSelection {
  list: IncompleteSurgeriesList[];
  count: number;
}
