import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, map, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { SessionStorageService } from './session-storage.service';
import { entitlements, facility, loginConfigs, surgeryFilters, userRoles, userStatus } from './constants/auth.constant';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { LoaderService } from './service/loader.service';
import { AdminUxDialogComponent } from '../components/admin-ux-dialog/admin-ux-dialog.component';
import { PostOpMeds } from '../model/generic.model';
import { PostOpMedicationsList } from './constants/constants';
import { JwtDecodeService } from './service/jwt-decode.service';
import { NavigationService } from './service/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private mccApiURL = environment.apiUrl + '/' + environment.ccApiRoute;
  roleName = '';
  accessType = '';
  permissions:any;
  userName = 'User';
  userId = '';
  practiseOrg = '';
  facilityName = '';
  userStatus = true;
  loggedIn = false;
  accountType = '';
  isAccountSwitched = false;
  public entitlementValue$ = new Subject<boolean>();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  public httpOptionsWithResponse = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response' as 'response'
  };

  private adminPermissions = [
    "asc",
    "clinics",
    "surgeons",
    "forms",
    "iolModel"
  ]

  //private currentUserDetails = new BehaviorSubject<any>({});
  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    public dialog: MatDialog,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private loaderService: LoaderService,
    private navigationService: NavigationService,
    private jwtDecodeService: JwtDecodeService
    ) {}

  get isFirstTimeLogin(): boolean {
    let authToken = this.sessionStorageService.getItem(loginConfigs.isUserLoggedIn);
    return authToken !== null ? true : false;
  }

  get isLoggedIn(): boolean {
    return this.loggedIn;
  }

  get isSuperadmin(): boolean {
    return this.roleName == userRoles.superAdmin ? true : false;
  }

  get isClinicStaff(): boolean {
    return this.roleName === userRoles.clinicAdmin || this.roleName === userRoles.clinicStaff;
  }

  get isAscStaff(): boolean {
    return this.roleName == userRoles.ascAdmin || this.roleName == userRoles.ascStaff ? true : false;
  }

  get isFacilityAdmin():boolean {
    return this.roleName == userRoles.ascAdmin || this.roleName == userRoles.clinicAdmin;
  }

  get isAscAccount():boolean{
    return this.accountType === facility.asc;
  }

  get isClinicAccount():boolean{
    return this.accountType === facility.clinic;
  }

  get isASCAdmin():boolean {
    return this.roleName == userRoles.ascAdmin;
  }
  
  getPracticeOrg() {
    let id = this.practiseOrg;
    return id !== null ? id : '';
  }
  
  getLoggedInUserRole() {
    return this.roleName;
  }
  extractData<T>(res: any, serviceUrl: string, apiOption: any) {
    // default response is 'response.body' unless requesting full response using 'observe':'response'
    const body = apiOption.hasOwnProperty('observe') && apiOption['observe'] === 'response' ? res.body : res;

    return <T>(body);
  }

  getTitles(): Observable<any> {
    return this.httpClient
      .get<any[]>(this.mccApiURL + '/titles', this.httpOptions)
  }

  getCountry(): Observable<any> {
    return this.httpClient
      .get<any[]>(this.mccApiURL + '/country/listing', this.httpOptions)
  }

  getState(): Observable<any> {
    const url = this.mccApiURL + '/states';
    return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
      map(resp => this.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  getRoleBaseUserlist(param: string): Observable<any> {
    return this.httpClient
      .get<any[]>(
        this.mccApiURL + '/user/typebased/listing?user_type=' + param,
        this.httpOptions
      )
  }

  getConcernForm(facilityId:any, surgeryType:any): Observable<any> {
    const url = this.mccApiURL + '/template?practiceOrg='+facilityId+'&surgeryType='+surgeryType;
    return this.httpClient.get<HttpResponse<any>>(url, this.httpOptionsWithResponse).pipe(
      map(resp => this.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  concernFormCreate(obj: any): Observable<any> {
    const url =  this.mccApiURL + '/template';
    return this.httpClient.post<any>(url, JSON.stringify(obj), this.httpOptionsWithResponse).pipe(
      map(resp => this.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  concernFormUpdate(id: any, obj: any): Observable<any> {
    const url =  this.mccApiURL + '/template/' + id;
    return this.httpClient.patch<any>(url, JSON.stringify(obj),this.httpOptionsWithResponse).pipe(
      map(resp => this.extractData<any>(resp, url, this.httpOptionsWithResponse))
    )
  }

  async logout()
  {
    this.loaderService.showLoader();
    this.sessionStorageService.removeItem(loginConfigs.isUserLoggedIn);
    this.sessionStorageService.removeItem(surgeryFilters.otherFilters);
    this.sessionStorageService.removeItem(surgeryFilters.headerFilters);
    this.logoutfromOkta()
  }

  async logoutfromOkta()
  {
    this.loaderService.showLoader();
    this.oktaAuth.signOut().then(() => {
      this.loaderService.hideLoader();

    });

}
openAlertDialog(msg: any) {
  const dialogRef = this.dialog.open(AlertDialogComponent, {
    data: {
      message: msg,
      buttonText: {
        cancel: 'Ok',
      },
    },
  });
}

  public isAdminGroup(permission: string){
    return this.adminPermissions.includes(permission);
  }

  public showDesktopOnlyModel(){
    this.dialog.open(AdminUxDialogComponent, {
      panelClass: 'admin-ux-panel',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop',
      data: {
        hideClose: true
      },
      disableClose: true
    });
  }

  addTimeZoneOffset(input: string | Date): Date | string {//use only for 2011-11-10 this format
    if(input) {
      let date=new Date(input)
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date;
    } else {
      return '';
    }
  }

  mapPostOpMeds(medsValue: string, otherMeds: string | undefined | null): string {
    const postOpMedsList: PostOpMeds[] = PostOpMedicationsList;
    if(medsValue === 'Other' && otherMeds) {
      return otherMeds;
    }
    const postOpMeds: PostOpMeds | undefined = postOpMedsList.find((meds) => {
      return meds.value === medsValue;
    });
    return postOpMeds?.label || medsValue;
  }

  async isAuthenticated(){
    if (this.oktaAuth.isLoginRedirect()) {
      await this.oktaAuth.handleLoginRedirect();
    }
    let isAuthenticated = await this.oktaAuth.isAuthenticated();
    let oktaToken;
    if (isAuthenticated) {
         oktaToken = this.oktaAuth.getAccessToken();
    }
    if (oktaToken && this.jwtDecodeService.sameClientId(oktaToken)) {
      return true;
    }
    return false;
  }

  hasAdiHomeToken(){
    const adiHome= new OktaAuth({
      issuer: environment.issuer,
      clientId: environment.adiHomeClientID,
      postLogoutRedirectUri: `${window.location.origin}/en_US/login`,
      scopes: ['openid', 'offline_access', 'profile', 'email'],
      tokenManager: {
        storage: 'cookie',
        storageKey:environment.adiHomeClientID,
      },
      cookies: {
        secure: true,
      },
    })
    if(adiHome.getAccessToken() || window.location.host.includes('localhost')){
      return true;
    }
    return false;
    
  }

  firstLogin(){
    if(this.roleName === userRoles.superAdmin) {
      this.navigationService.navigateTo(['clinics']);

    }else{
      this.navigationService.navigateTo(['homepage']);
    }
  }
}
