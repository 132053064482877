import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AdminUxDialogComponent } from '../../admin-ux-dialog/admin-ux-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { DeviceService } from 'src/app/shared/device.service';
import { AuthService } from 'src/app/shared/auth.service';
import { CommonModule } from '@angular/common';
import { NavigationService } from 'src/app/shared/service/navigation.service';

@Component({
  selector: 'app-sub-header',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.scss'
})
export class SubHeaderComponent implements OnInit{
  isMobile = false; // You'll set this based on the viewport width
  isSurgeryUrl = false;
  isAdmin = false;
  isHomepageUrl = false;
  showMobileHeader = false;
  superAdmin = false;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private deviceService: DeviceService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public navigationService: NavigationService
  ) {

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateFlags(event.url);
        }
      });
   
    
  }
  ngOnInit(): void {
    this.superAdmin = this.authService.getLoggedInUserRole() === 'superadmin';
    this.updateFlags(this.router.url);
  }

  toggleMenu() {
    this.showMobileHeader = !this.showMobileHeader;
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 768; // Example breakpoint for mobile
  }

  private updateFlags(url: string): void {
    this.isSurgeryUrl = url.includes('surgeries');
    this.isHomepageUrl = url.includes('homepage');
    this.isAdmin = url.includes("clinics") || url.includes("surgeons") || url.includes("asc") || url.includes("forms");
  }

  getPageRedirect(header: string)
  {
    this.showMobileHeader = false;
    const deviceType: string = this.deviceService.getDeviceType()
    if(header === 'surgeries'){
      this.navigationService.navigateTo(['surgeries']);
    }
    else if(header === 'home'){
      this.navigationService.navigateTo(['homepage']);
    }
    else{
      if(deviceType === 'mobile' || deviceType === 'tablet' ) {
        const dialogRef = this.dialog.open(AdminUxDialogComponent, {
          panelClass: 'admin-ux-panel',
          hasBackdrop: true,
          backdropClass: 'dialog-backdrop'
        });
      }
      else{
        if(this.superAdmin)
        {
          this.navigationService.navigateTo(['clinics']);
        }
        else
        {
          this.navigationService.navigateTo(['surgeons']);
        }
      }
    }
  }
}
