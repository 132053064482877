import { Injectable } from "@angular/core"
import { AuthService } from "../auth.service"
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SharedService } from "./shared.service";
import { map } from "rxjs";
import { alconHttpOptions, preferenceTypes } from "../constants/constants";

export interface ascSettingsPayload {
    practiceOrg: string,
    practiceType : string,
    preferenceType:  string,
    timeSettings: timeSettings
}

export interface timeSettings {
    firstPatientArrivalTime: string,
    standardPatientArrivalTime : string
}
   

@Injectable({
    providedIn: 'root'
  })
  export class AlconASCSettingsService {
  
    private mccApiURL = environment.apiUrl + '/' + environment.ccApiRoute;


    constructor(
     private authService: AuthService,
     private httpClient: HttpClient,
     private sharedService: SharedService,
     ) { 
    }
  
  //Get ASC Settings
  getAscSettings(){
    const url = this.mccApiURL + '/preferences?practiceOrg='+this.authService.practiseOrg+ `&practiceType=${this.authService.accountType}&preferenceType=${preferenceTypes.arrivalTime}`;
    return this.httpClient
        .get<any[]>(url, alconHttpOptions
          );
  }

  //Save ASC Settings
  savePreferencesAPI(ascTimeSettings:timeSettings){
    const url = this.mccApiURL + '/preferences';
    let payload:ascSettingsPayload = {
      practiceOrg: this.authService.practiseOrg,
      practiceType: this.authService.accountType,
      preferenceType: preferenceTypes.arrivalTime,
      timeSettings: ascTimeSettings
    }
     return this.httpClient.post<HttpResponse<any>>(url, JSON.stringify(payload),alconHttpOptions).pipe(
       map(resp =>  resp.body)
     )
}
  }