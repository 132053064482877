import { Component, Inject } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { SessionStorageService } from 'src/app/shared/session-storage.service';
import { ClinicService } from '../../shared/clinic.service';
import { UserService } from 'src/app/shared/user.service';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IdleService } from '../../shared/service/idle.service';
import { loginConfigs, okta2FaAcrConfig, previousSessionConfigs } from 'src/app/shared/constants/auth.constant';
import { NavigationService } from 'src/app/shared/service/navigation.service';
import { userRoleCode, userRoles, userStatus } from 'src/app/shared/constants/auth.constant';
import { JwtDecodeService } from 'src/app/shared/service/jwt-decode.service';
import { RedirectService } from 'src/app/shared/redirect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  facilities: any = [];
  showLogin = false;
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public authStateService: OktaAuthStateService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public service: AuthService,
    public clinicservice: ClinicService,
    public userService: UserService,
    public dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private idleService: IdleService,
    public navigationService: NavigationService,
    private jwtDecodeService: JwtDecodeService,
    private redirectService: RedirectService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.redirectService.redirectIfNeeded();
    const isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (isAuthenticated)
    {
      const oktaToken = this.oktaAuth.getAccessToken();
      if (oktaToken && this.jwtDecodeService.sameClientId(oktaToken)){
        this.getUserDetails();
      }else{
        await this.oktaAuth.signInWithRedirect({acrValues: okta2FaAcrConfig});
      }
    }else if(this.activatedRoute?.snapshot?.data['isAutomaticLogin']){
      this.login();
    }
    else
    {
      this.showLogin = true;
      // await this.oktaAuth.signInWithRedirect();
    }
  }

  getUserDetails(){
    
           if(this.authService.userStatus)
           {
           
              let loginUrlPath = this.sessionStorageService.getItem(loginConfigs.loginUrl)
              let loginQueryParams = this.sessionStorageService.getItem(loginConfigs.loginQuery)
              if (loginUrlPath !="" && loginUrlPath !=null) {
                // this.navigationService.navigateTo(['surgeries',loginUrlPath], loginQueryParams);
                let url = loginUrlPath.split('/').filter((val: string) => val !== "");
                this.router.navigate([...url], {queryParams:loginQueryParams, replaceUrl: true});

              }else{
                if(this.authService.roleName === userRoles.superAdmin) {
                  this.navigationService.navigateTo(['clinics']);

                }else{
                  this.navigationService.navigateTo(['homepage'], loginQueryParams);
                }
              }
              this.router.events.subscribe(event=> {
                if (event instanceof NavigationEnd){
                  this.sessionStorageService.removeItem(loginConfigs.loginUrl);
                  this.sessionStorageService.removeItem(loginConfigs.loginQuery);
                }
              })
          //  }
          }
        
         
        else
        {
          this.navigationService.navigateTo(['account-not-found']);
        }
      }
    
  openAlertDialog(msg: any) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: 'Ok',
        },
      },
    });
  }

  async login()
  {
    await this.oktaAuth.signInWithRedirect({acrValues: okta2FaAcrConfig});
  }

}
