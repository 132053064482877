import { Injectable } from '@angular/core';
import { userRoles, accessType, clinicConnectConfig, facility, entitlements } from '../constants/auth.constant';
import { AuthService } from '../auth.service';
import { PermissionService } from './permission.service';
import { SharedService } from './shared.service';
import { SessionStorageService } from '../session-storage.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  constructor(
    private permissionService: PermissionService,
    private authService: AuthService,
    private sharedService: SharedService,
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService
  ) { }

  setConfig(res: entitlements){

    if (!res || (res && !res.associatedAccounts)){
      console.log("Show Error Page");
      return;
    }
     
    let currentAccount;
    if(res && res.associatedAccounts && res.associatedAccounts[0]){
      currentAccount = res.associatedAccounts[0];
    }
    this.authService.userName = res.firstName;
    this.authService.userId = res.id;
    this.authService.loggedIn = true;
    if(currentAccount){
      this.authService.roleName = currentAccount.permissions?.CC.permissionSet?.isSuperAdmin === true ? userRoles.superAdmin : '';
      this.authService.permissions = currentAccount.permissions?.CC.permissionSet;
      this.authService.practiseOrg = currentAccount.practiceOrg;
      this.authService.facilityName = currentAccount.name;
      this.authService.userStatus = currentAccount.permissions?.CC.active;
      this.authService.accessType = currentAccount.accessType;
      this.authService.accountType = currentAccount.type;
      this.setRoles();

      this.permissionService.setPermission();
      if(!sessionStorage.getItem(clinicConnectConfig.cc_current_account)){
        sessionStorage.setItem(clinicConnectConfig.cc_current_account, currentAccount.sapId);
      }else if(sessionStorage.getItem(clinicConnectConfig.cc_current_account) !== currentAccount.sapId){
        sessionStorage.setItem(clinicConnectConfig.cc_current_account, currentAccount.sapId);
        this.authService.isAccountSwitched = true;
        this.sharedService.callAccountSwitchEvents();
      }       

      if(this.utilityService.formatSapId(this.sessionStorageService.getItem(clinicConnectConfig.cc_changed_sapId)) !== this.utilityService.formatSapId(currentAccount.sapId)){
        this.sapIdNotMatchInUrl();
      }
    }
  }
  setRoles(){
    if(this.authService.roleName !== userRoles.superAdmin)
    {
      if(this.authService.accountType === facility.asc){
        this.authService.roleName = this.authService.accessType === accessType.userManage ? 
                                            userRoles.ascAdmin : userRoles.ascStaff;
      }else{
        this.authService.roleName = this.authService.accessType === accessType.userManage ? 
        userRoles.clinicAdmin : userRoles.clinicStaff;
      }
    }
  }
  sapIdNotMatchInUrl(){
    if(this.sessionStorageService.getItem(clinicConnectConfig.cc_changed_sapId)){
      this.authService.firstLogin();
      this.sessionStorageService.removeItem(clinicConnectConfig.cc_changed_sapId);
    }
  }

  }


